import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import Image from "gatsby-image"
import { Box, Card, Container, Grid, Typography } from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import RoomIcon from "@material-ui/icons/Room"
import Layout from "../components/Layout/layout"
import ContactForm from "../components/Layout/ContactForm"

import GoogleMapReact from "google-map-react"
const Contact = ({ classes, data }) => {
  const {
    mdx: {
      frontmatter: {
        category,
        title,
        date,
        slug,
        tagline,
        businessUnit,
        image01,
      },
      id,
      body,
    },
  } = data
  const img01 = image01.childImageSharp.fluid
  var center = { lat: 41.971677555175845, lng: -87.64369197354635 }
  var zoom = 14

  return (
    <Layout>
      <Box className={classes.mapBox}>
        {/* <Typography>Update google map react component // React 16.* dependent</Typography> */}
        <GoogleMapReact
          bootstrapURLKeys={{
            key: "AIzaSyBB - bCI267AVbgjh5nXaz8GCj_HxEjf6TQ",
          }}
          defaultCenter={center}
          defaultZoom={zoom}
        >
          <RoomIcon
            style={{ fontSize: "20px", color: "#FF0000" }}
            lat={41.97549159960473}
            lng={-87.69142268773538}
            text="My Marker"
          />
          <RoomIcon
            style={{ fontSize: "20px", color: "#FF0000" }}
            lat={41.971677555175845}
            lng={-87.69142268773538}
            text="My Marker"
          />
        </GoogleMapReact>
      </Box>
      <Container className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xxs={12} xs={12} md={6}>
            <Typography className={classes.itemPageHeader} gutterBottom>
              Contact {businessUnit}
            </Typography>
            <Typography variant="body1" className={classes.copy}>
              Interested in working with {businessUnit}? Are you a manufacturer
              with a great new product to pitch? Get in touch with us here!
            </Typography>
            <ContactForm />
          </Grid>
          <Grid item xxs={12} xs={12} md={6}>
            <Image fluid={img01} alt={tagline} />
          </Grid>
        </Grid>
      </Container>
    </Layout>
  )
}

const styles = theme => ({
  root: {
    minWidth: 225,
    flexGrow: 1,
    margin: theme.spacing(0, 0.5, 0.5, 0.5),
  },
  container: {
    marginTop: 25,
  },
  mapBox: {
    width: "100%",
    height: "90vh",
  },
  addressBox: {
    width: theme.spacing(10),
    height: theme.spacing(20),
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  itemPageHeader: {
    fontSize: "48px",
    fontWeight: "light",
    letterSpacing: "3px",
    textTransform: "uppercase",
    lineHeight: "97%",
  },
  copy: {
    fontSize: "18px",
    fontWeight: "light",
  },
})

export const query = graphql`
  query GetCntactInfo($slug: String) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        title
        category
        businessUnit
        date(formatString: "MMMM Do, YYYY")
        slug
        tagline
        image01 {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      id
      body
    }
  }
`
export default withStyles(styles)(Contact)
