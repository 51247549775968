import React, { useState, useContext } from "react"
import {
  Button,
  Collapse,
  Divider,
  FormControl,
  Grid,
  NoSsr,
  TextField,
  Typography,
} from "@material-ui/core"
import { Alert, AlertTitle } from "@material-ui/lab"
import withStyles from "@material-ui/core/styles/withStyles"
import gql from "graphql-tag"
import { useMutation } from "@apollo/client"
import MuiPhoneNumber from "mui-phone-input-ssr"
import CheckBoxIcon from "@material-ui/icons/CheckBox"
import { useSiteMetadata } from "../utils/hooks/useSiteMetadata"
import { useLocation } from "@reach/router"
const phoneTypes = [
  { id: 1, name: "Mobile" },
  { id: 2, name: "Office" },
  { id: 3, name: "Home" },
  { id: 4, name: "App" },
]

const ContactForm = ({ classes }) => {
  const [submitting, setSubmitting] = useState(false)
  const [success, setSuccess] = useState(false)
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [org, setOrg] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [phoneType, setPhoneType] = useState(1)
  const [message, setMessage] = useState("")
  const location = useLocation()
  const source = location.href
  const { sbuId } = useSiteMetadata()
  const businessUnitId = parseInt(sbuId)
  function handlePhoneChange(value) {
    setPhone(value)
  }
  const [handleSubmit, { data, loading, error }] = useMutation(
    CONTACT_FORM_MESSAGE_MUTATION,
    {
      variables: {
        sbuId: businessUnitId,
        source: source,
        message: message,
        email: email,
        phone: phone,
        firstName: firstName,
        lastName: lastName,
        organization: org,
      },
      onCompleted: data => {
        setSubmitting(false)
        setSuccess(true)
        setFirstName("")
        setLastName("")
        setOrg("")
        setEmail("")
        setPhone("")
        setMessage("")
      },
    }
  )
  return (
    <div className={classes.infoSection}>
      <form
        onSubmit={event => {
          event.preventDefault()
          setSubmitting(true)
          handleSubmit(event)
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <FormControl required fullWidth>
              <TextField
                label="First Name"
                id="firstName"
                helperText="(required)"
                value={firstName}
                onChange={event => setFirstName(event.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl required fullWidth>
              <TextField
                label="Last Name"
                id="lastName"
                helperText="(required)"
                value={lastName}
                onChange={event => setLastName(event.target.value)}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              label="Organization"
              id="org"
              value={org}
              fullWidth
              onChange={event => setOrg(event.target.value)}
            />
          </Grid>
          <Divider />

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth required>
              <TextField
                label="Email Address"
                id="email"
                type="email"
                helperText="(required)"
                value={email}
                onChange={event => setEmail(event.target.value)}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <MuiPhoneNumber
                label="Phone Number"
                id="phone"
                value="phone"
                fullWidth
                variant="outlined"
                margin="dense"
                size="small"
                defaultCountry="us"
                regions={["america", "europe", "asia"]}
                disableAreaCodes
                onChange={handlePhoneChange}
              />
              {/* <NoSsr>
                <MuiPhoneInput
                  label="Phone Number"
                  id="phone"
                  value="phone"
                  fullWidth
                  defaultCountry="us"
                  regions={["america", "europe", "asia"]}
                  disableAreaCodes
                  onChange={handlePhoneChange}
                />
              </NoSsr> */}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                multiline
                variant="outlined"
                rows={4}
                label="Message"
                id="message"
                value={message}
                onChange={event => setMessage(event.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item xxs={12} zs={12}>
            <CheckBoxIcon style={{ fontSize: "19px" }} />
            <Typography style={{ display: "inline", padding: "10px" }}>
              <strong>No unnecessary emails, calls, or texts!</strong> And, of
              course, you're welcome to view our privacy policy.
            </Typography>
          </Grid>

          <Divider />

          <Grid item xs={12} sm={6}>
            {success && (
              <Alert onClose={() => setSuccess(false)}>
                Thanks for your message. We'll be in touch!
              </Alert>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={
                  submitting ||
                  !firstName.trim() ||
                  !lastName.trim() ||
                  !email.trim()
                }
              >
                Contact ICON Emblem
                {/* {loading ? "Submitting..." : "Contact ICON Emblem"} */}
              </Button>
            </FormControl>
          </Grid>
        </Grid>
      </form>
    </div>
  )
}
const styles = theme => ({
  root: {
    minWidth: 225,
    flexGrow: 1,
  },
  infoSection: {
    marginTop: theme.spacing(4),
  },
})
const CONTACT_FORM_MESSAGE_MUTATION = gql`
  mutation (
    $sbuId: Int!
    $source: String
    $message: String
    $email: String
    $phone: String
    $firstName: String
    $lastName: String
    $organization: String
  ) {
    createContactFormMessage(
      sbuId: $sbuId
      source: $source
      message: $message
      email: $email
      phone: $phone
      firstName: $firstName
      lastName: $lastName
      organization: $organization
    ) {
      contactFormMessage {
        id
        message
      }
    }
  }
`
export default withStyles(styles)(ContactForm)
